var $ = require("jquery");
window.jQuery = $;
/* global wc_add_to_cart_params */
//$ =jQuery.noConflict();
const qpcTriggerCheckout = (qpcTriggerCheckout) => {
  $( document.body ).on('added_to_cart', function(e){
    $(document.body).trigger("update_checkout"); }
    );

}
export { qpcTriggerCheckout };

