//import barba from '@barba/core';
import Swiper from "swiper/bundle";
import { gsap } from "gsap";
import { qpcTriggerCheckout } from "./inc/qpc-trigger-checkout.js";

var $ = require("jquery");
window.jQuery = $;

//require("@fancyapps/fancybox");
//import "jquery-ui";

function delay(n) {
  n = n || 2000;
  return new Promise((done) => {
    setTimeout(() => {
      done();
    }, n);
  });
}


function qpcScroll() {
  $(function () {
    let scrollable = document.querySelector('[data-barba="container"]');
    $('[data-barba="wrapper"]').addClass("qpcScrollWrapper");
    $('[data-barba="container"]').addClass("qpcScrollContainer");

    let current = 0;
    let target = 0;
    let ease = 0.075;

    // Funzione Linear ineterpolation https://en.wikipedia.org/wiki/Linear_interpolation
    function lerp(start, end, t) {
      return start * (1 - t) + end * t;
    }

    // Triggero il body e imposto l'altezza del documento
    function initScroll() {
      document.body.style.height = `${
        scrollable.getBoundingClientRect().height
      }px`;
      //  var pippo = $(scrollable).outerHeight();
      //  $('body').css('height', pippo );
    }

    // Imposto il div per gestire lo smooth scroll tramite la lerp function
    function smoothScroll() {
      target = window.scrollY;
      current = lerp(current, target, ease);
      scrollable.style.transform = `translate3d(0,${-current}px, 0)`;
      requestAnimationFrame(smoothScroll);
    }

    initScroll();

    smoothScroll();

    setTimeout(() => window.dispatchEvent(new Event("resize")), 100);
  });
}

//  Animazione per tutte le pagine
function pageTransition() {
  var tl = gsap.timeline();

  tl.to(".loading-container", {
    duration: 1.2,
    width: "100%",
    top: "0",
    ease: "Expo.easeInOut",
  });

  tl.to(".loading-container", {
    duration: 1,
    width: "100%",
    top: "100vh",
    ease: "Expo.easeInOut",
    delay: 0.3,
  });
  tl.set(".loading-container", { top: "-100vh" });
}

$(function () {
  //autocompleteMPR();
  

  if ($("#billing_customertype").length > 0) {
    $("#billing_customertype").trigger("change");
  }
  qpcTriggerCheckout();

  $(".search-button-menu,.close-search-overlay").on("click", function () {
    $(".search-overlay").toggleClass("visible");
  });

  var swiperGalleryThumb = new Swiper(".swiper-gallery-thumb", {
    speed: 600,
    autoHeight: true,
    calculateHeight: true,
    autoplay: {
      delay: 4000,
      disableOnInteraction: false,
    },
    spaceBetween: 20,
    slidesPerView: 4,
    freeMode: true,
  });

  var swiperGallery = new Swiper(".swiper-gallery", {
    speed: 600,
    autoHeight: true,
    calculateHeight: true,
    autoplay: {
      delay: 4000,
      disableOnInteraction: false,
    },
    breakpoints: {
      1024: {
        slidesPerView: 1,
        spaceBetween: 0,
      },
    },
    navigation: {
      nextEl: ".gallery-prodotto .swiper-button-next",
      prevEl: ".gallery-prodotto .swiper-button-prev",
    },
    thumbs: {
      swiper: swiperGalleryThumb,
    },
  });

  var swiperGallery = new Swiper(".swiper-gallery-correlati", {
    speed: 600,
    autoHeight: true,
    calculateHeight: true,
    spaceBetween: 0,
    slidesPerView: 1,
    autoplay: {
      delay: 4000,
      disableOnInteraction: false,
    },
    breakpoints: {
      768: {
        slidesPerView: 3,
        spaceBetween: 20,
      },
    },
    pagination: {
      el: ".swiper-gallery-correlati .swiper-pagination",
      clickable: true,
    },
  });

  var swiper1 = new Swiper(".swiper1", {
    speed: 1,
    autoplay: {
      delay: 4000,
      disableOnInteraction: false,
    },
    pagination: {
      el: ".swiper-pagination1",
      clickable: true,
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    on: {
      slideChange: function () {
        $(".swiper-slide.swiper-slide-active").removeClass("mos");
      },
      init: function () {
        $(".swiper-slide.swiper-slide-active").addClass("mos");
      },
      transitionStart: function () {
        $(".swiper-slide.swiper-slide-active").addClass("mos");
      },
    },
  });

  var swiper2 = new Swiper(".swiper2", {
    speed: 600,
    loop: true,
    autoHeight: true,
    calculateHeight: true,
    autoplay: {
      delay: 4000,
      disableOnInteraction: false,
    },
    breakpoints: {
      1024: {
        slidesPerView: 3,
        spaceBetween: 30,
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 30,
      },
      320: {
        slidesPerView: 2,
        spaceBetween: 10,
      },
    },
    // init: false,
    pagination: {
      el: ".swiper-pagination2",
      clickable: true,
    },
    navigation: {
      nextEl: ".swiper-button-next2",
      prevEl: ".swiper-button-prev2",
    },
  });

  var swiper3 = new Swiper(".swiper3", {
    slidesPerView: 4,
    spaceBetween: 30,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    // init: false,
    pagination: {
      el: ".swiper-pagination3",
      clickable: true,
    },
    breakpoints: {
      1024: {
        slidesPerView: 3,
        spaceBetween: 10,
      },
      768: {
        slidesPerView: 3,
        spaceBetween: 30,
      },
      640: {
        slidesPerView: 1,
        spaceBetween: 20,
      },
      320: {
        slidesPerView: 1,
        spaceBetween: 10,
      },
    },
  });
  //backToTheTop
  var offset = 300,
    offset_opacity = 1200,
    scroll_top_duration = 700;
  var $back_to_top = $(".qpc-top");
  $(window).scroll(function () {
    $(this).scrollTop() > offset
      ? $back_to_top.addClass("qpc-is-visible")
      : $back_to_top.removeClass("qpc-is-visible");
    if ($(this).scrollTop() > offset_opacity) {
      $back_to_top.addClass("qpc-fade-out");
    }
  });
  function customerTypePrivato() {
    var billing_company_field = $("#billing_company_field");
    var billing_codice_sdi_field = $("#billing_codice_sdi_field");
    var billing_vat_number_field = $("#billing_vat_number_field");
    var billing_cf_field = $("#billing_cf_field");
    var artt_b2b_wrapper = $(".woocommerce-artt_b2b-wrapper");

    $(billing_company_field).hide();
    $(billing_codice_sdi_field).hide();
    $(billing_vat_number_field).hide();
    $(billing_company_field).removeClass("validate-required");
    $(billing_codice_sdi_field).removeClass("validate-required");
    $(billing_vat_number_field).removeClass("validate-required");
    $(billing_cf_field).addClass("validate-required");
    $('label[for="billing_cf"] span.optional').remove();
    $('label[for="billing_cf"]').append(
      '<abbr class="required" title="obbligatorio">*</abbr>'
    );

    $('label[for="billing_company"] abbr.required').remove();
    $('label[for="billing_codice_sdi"] abbr.required').remove();
    $('label[for="billing_vat_number"] abbr.required').remove();

    $(artt_b2b_wrapper).hide();
  }

  function customerTypeAzienda() {
    var billing_company_field = $("#billing_company_field");
    var billing_codice_sdi_field = $("#billing_codice_sdi_field");
    var billing_vat_number_field = $("#billing_vat_number_field");
    var billing_cf_field = $("#billing_cf_field");
    var artt_b2b_wrapper = $(".woocommerce-artt_b2b-wrapper");

    $(billing_company_field).show();
    $(billing_codice_sdi_field).show();
    $(billing_vat_number_field).show();
    $(billing_company_field).addClass("validate-required");
    $(billing_codice_sdi_field).addClass("validate-required");
    $(billing_vat_number_field).addClass("validate-required");
    $(billing_cf_field).removeClass("validate-required");

    $('label[for="billing_company"]')
      .append('<abbr class="required" title="obbligatorio">*</abbr>')
      .children("span.optional")
      .remove();
    $('label[for="billing_codice_sdi"]')
      .append('<abbr class="required" title="obbligatorio">*</abbr>')
      .children("span.optional")
      .remove();
    $('label[for="billing_vat_number"]')
      .append('<abbr class="required" title="obbligatorio">*</abbr>')
      .children("span.optional")
      .remove();

    $('label[for="billing_cf"] span.optional').remove();
    $('label[for="billing_cf"]')
      .append('<span class="optional">(opzionale)</span>')
      .children("abbr.required")
      .remove();

    $(artt_b2b_wrapper).show();
  }

  $("#billing_customertype").on("change", function () {
    var billing_company_field = $("#billing_company_field");
    if ($(this).val() == "privato") {
      customerTypePrivato();
    } else {
      customerTypeAzienda();
    }
  });
  //smooth scroll to top
  $back_to_top.on("click", function (event) {
    event.preventDefault();
    $("body,html").animate(
      {
        scrollTop: 0,
      },
      scroll_top_duration
    );
  });
  //$(".blocks-gallery-item figure a, .wpml-ls-link, .ab-item, #wp-toolbar a, #wpadminbar a, .toggle, .popout, .extra-menu a, #wp-toolbar a").addClass("no-barba");
  //console.log($('.page-template-default #wrap-custom-post'));
  $(".wp-block-columns, .wp-block-group").addClass("qpc-container");
  $("#page-4390 .woocommerce").addClass("qpc-container");
  $(".sbsi7l-0").css("display", "none");
  //$('.page-template-default #wrap-custom-post').addClass('qpc-container');
  /* var blank = 'noopener';
 $('a').filter('a[rel^="'+blank+'"]').addClass('no-barba');
 $('#menu-main-menu li.no-barba a').addClass('no-barba');
 $('.wp-block-gallery a').addClass('no-barba'); */
});

$(window).scroll(function () {
  $(".qpc-trigger").each(function () {
    if (
      $(window).scrollTop() + $(window).height() >= $(this).position().top &&
      $(window).scrollTop() < $(this).position().top + $(this).height()
    ) {
      if (!$(this).hasClass("qpc-inview")) {
        $(this).addClass("qpc-inview");
      }
    } else if ($(this).hasClass("qpc-inview")) {
      $(this).removeClass("qpc-inview");
    }
  });
});

/*
  $(' .wp-block-image, .blocco-text-img .wp-block-cover').addClass('qpc-trigger text-focus-in');
  $(' .wp-block-group .wp-block-group__inner-container, article .wp-block-cover, .wp-block-column p').addClass('qpc-trigger fade-in-bottom');
  */

jQuery(".wp-block-gallery").each(function () {
  jQuery(this)
    .find("figure a")
    .attr("data-fancybox", "group-Gallery" + $(this).parent().attr("id"));
  var caption = $(this).find(".wp-caption-text").text();
  //alert(caption);
  jQuery(this).find(".immagine-gallery").attr("title", caption);
});

$(".toggle, .close-menu").on("click", function (event) {
  /* $('header.fixed-header').addClass('sticky'); */
  //$(".popout").toggle({ direction: "left" }, 300, function(){});
  $(".popout").toggleClass("popout-open");
  $(".menu-main-menu-container").addClass("popout-active");

  //  event.stopImmediatePropagation(); // lo utilizzo per barba altrimenti il toggle viene richiamato 2 volte
});

/* $('.menu-item-has-children a').on('click', function(e){
      var elem = $(e.target);

      if(elem.hasClass('open-menu')) {
        elem.removeClass('open-menu');
      } else {
        elem.addClass('open-menu');
      }
  }); */

var submenuitem = $("ul.menu-qpc-class .menu-item-has-children > a");
//submenuitem.after('<i class="arrow-menu"></i>');

if ($(window).width() < 1025) {
  $(".prevent-click > a").addClass("no-barba");
  $(".prevent-click > a").on("click", function (event) {
    event.preventDefault();
  });
}

var submenuitemsub = $("ul.menu-qpc-class ul .menu-item-has-children > a");
submenuitemsub.each(function (index, elem) {
  if ($(window).width() > 1024) return;

  var jelem = $(elem);
  var href = jelem.attr("href");
  var textlink = jelem.text();

  jelem.attr("href", "#");
  jelem
    .next()
    .prepend(
      '<div class="category-link"><a href="' +
        href +
        '">Vai a: ' +
        textlink +
        "</a></div>"
    );
});

/* $('i.arrow-menu').on('click', function(e){
        if($(window).width() > 1024) return;

        var elem = $(e.target).prev();

        if(elem.hasClass('open-menu')) {
          elem.removeClass('open-menu');
        } else {
          elem.addClass('open-menu');
        }
      }); */

submenuitem.on("click", function (e) {
  if ($(window).width() > 1024) return;

  //$('li.shop-menu > a').removeClass('open-menu');
  var elem = $(e.target);

  if (elem.hasClass("open-menu")) {
    elem.removeClass("open-menu");
  } else {
    elem.addClass("open-menu");
  }
});

// pareggio l'altezza del titolo nel caso andasse su 2 righe
$(".qpc-col-33 h3").wrap("<div class='h3-wrapper'></div>");
var maxHeight = 0;
$(".h3-wrapper")
  .each(function () {
    if ($(this).outerHeight() > maxHeight) {
      maxHeight = $(this).outerHeight();
    }
  })
  .height(maxHeight);

$(".calendario-template .mc-description.news-home a").addClass(
  "autoHeightTitleDesc"
);
$(".autoHeightTitleDesc").wrap("<div class='mc-d-wrapper'></div>");
var maxHeight = 0;
$(".mc-d-wrapper")
  .each(function () {
    if ($(this).outerHeight() > maxHeight) {
      maxHeight = $(this).outerHeight();
    }
  })
  .height(maxHeight);

// Prendo l'elemento tab__content più alto
let height = -1;
$(".tab__content").each(function () {
  height = height > $(this).outerHeight() ? height : $(this).outerHeight();
  $(this).css("position", "absolute");
});
// Setto l'altezza del tab prendendo l'elemento più alto di tab__content
$("[data-tabs]").css("height", height + 100 + "px");

// SCROLLING ANCORE
/* va in conflitto con le frecce del bootstrap-carousel e i tab jquery, mettere un selettore.*/
$("a[href*=\\#]:not([href=\\#])").click(function () {
  if (
    (location.pathname.replace(/^\//, "") == this.pathname.replace(/^\//, "")) |
    (location.hostname == this.hostname)
  ) {
    var target = $(this.hash);
    target = target.length ? target : $("[name=" + this.hash.slice(1) + "]");
    if (target.length) {
      $("html,body").animate(
        {
          scrollTop: target.offset().top,
        },
        1000
      );
      return false;
    }
  }
});

/*
  var heightSwiper = $('.cont-swiper-news').innerHeight();
  $( ".cont-swiper-news" ).css( "min-height", heightSwiper );
*/

$(function () {
  // qpcTriggerCheckout();

  if ($(window).width() < 1025) return;
  $(window).scroll(function () {
    var scroll = $(window).scrollTop();
    if (scroll >= 100) {
      //$(".wrap-header").addClass("sticky");
      $("header.fixed-header").addClass("sticky");
      $("#page-logo-sticky").show();
      $("#menu-pre-header-sticky").show();
    } else {
      //$(".wrap-header").removeClass("sticky");
      $("header.fixed-header").removeClass("sticky");
      $("#page-logo-sticky").hide();
      $("#menu-pre-header-sticky").hide();
    }
  });

  // su mobile risolvo il click della tendina nel caso ci fosse assegnato un url
  $(".menu-qpc-class li.menu-item-has-children > a").on(
    "click",
    function (event) {
      event.preventDefault();
    }
  );

  /* }); */

  // Evito il ricaricamento di Barba nella pagina corrente
  var links = document.querySelectorAll("a[href]");
  var cbk = function (e) {
    if (e.currentTarget.href === window.location.href) {
      e.preventDefault();
      e.stopPropagation();
    }
  };
  for (var i = 0; i < links.length; i++) {
    links[i].addEventListener("click", cbk);
  }

  function equalHeight(classElem = "equalHeight") {
    var elements = document.getElementsByClassName(classElem),
      allHeights = [],
      i = 0;

    for (i = 0; i < elements.length; i++) {
      elements[i].style.height = "auto";
    }

    for (i = 0; i < elements.length; i++) {
      var elementHeight = elements[i].clientHeight;
      allHeights.push(elementHeight);
    }

    for (i = 0; i < elements.length; i++) {
      elements[i].style.height = Math.max.apply(Math, allHeights) + "px";
    }
  }

  function get_data_filter(filtro) {
    jQuery.ajax({
      type: "POST",
      dataType: "json",
      url: filter_ajax_object.ajax_url,
      data: {
        action: "get_data_filter",
        filtro: filtro,
      },
      success: function (response) {
        //console.log(response, 'success');
        insertProduct(response);
      },
      error: function (error) {
        console.log(error, "error");
        jQuery(".woocommerce-pagination").remove();
        jQuery(".page-archive").empty();
        jQuery(".loader-filtro").removeClass("loading");
        jQuery("#risultato-filtro").append("<br/>Nessun risultato");
      },
    });
  }

  function insertProduct(products) {
    jQuery(".woocommerce-pagination, .navigation").remove();
    jQuery(".page-archive").empty();

    products.forEach(function (product, index) {
      let id = product[0];
      let link = product[1];
      let titolo = product[2];
      let descrizione = product[3];
      let prezzo = product[4];
      let immagine = product[5];
      if (immagine === false) {
        immagine = [];
        immagine.push(
          "https://tester.quartopianocomunicazione.it/site_quadrica_2022/wp-content/themes/mos/images/placeholder.png",
          555,
          555
        );
      }

      let html = `<div class="item-prodotto prodotto-cat">
          <div class="preview">
            <a href="${link}">
              <img src="${immagine[0]}" width="${immagine[1]}" height="${immagine[2]}" alt="">
            </a>
          </div>
          <a href="${link}" class="subtitle">${titolo}</a>
          <div class="descrizione">${descrizione}</div>
          <a href="${link}" class="button">Vedi prodotti</a>
      </div>`;

      /* let html = '<div class="product-item product type-product status-publish has-post-thumbnail sold-individually purchasable product-type-simple"><a href="'+link+'"><div class="product-thumb"><img width="'+immagine[1]+'" height="'+immagine[2]+'" src="'+immagine[0]+'" class="attachment-woocommerce_thumbnail size-woocommerce_thumbnail" alt="" loading="lazy"></div><div class="product-data"><div class="product-title equalTitle">'+titolo+'</div><div class="product-description equalDescription">'+descrizione+'</div><div class="product-price">'+prezzo+' €</div></div></a><a href="'+link+'" class="button-secondary product-button button-moredetail">Approfondisci</a></div>'; */

      jQuery(".page-archive").append(html);
    });

    /* equalHeight('equalDescription');
  equalHeight('equalTitle'); */
    jQuery(".loader-filtro").removeClass("loading");
  }

  let form_filtro = jQuery("#form-filtro");
  let button_reset = jQuery("#button-reset");

  button_reset.on("click", function (e) {
    e.preventDefault();
    form_filtro.trigger("reset");
    jQuery("#risultato-filtro").empty();
  });

  if (form_filtro.length > 0) {
    form_filtro.submit(function (event) {
      event.preventDefault();
      let form_data = jQuery(this).serialize();
      console.log(form_data);
      console.log(parseQuery(form_data));
      let select_filtro = parseQuery(form_data);
      let text_select_filtro = "Risultati per: ";
      let lunghezza_filtro = Object.keys(select_filtro).length;
      let n = 1;

      if (lunghezza_filtro > 0) {
        Object.keys(select_filtro).forEach(function (item) {
          //console.log(item);
          if (item !== "filtrocategory") {
            text_select_filtro +=
              "<strong>" +
              item.split("_")[0].toUpperCase() +
              ":</strong> " +
              select_filtro[item].toUpperCase();
            //console.log(n, lunghezza_filtro);
            if (n < lunghezza_filtro) text_select_filtro += ", ";
            n++;
          }
        });

        //console.log(text_select_filtro);

        jQuery("#risultato-filtro").empty().append(text_select_filtro);
      } else {
        location.reload();
        return;
      }

      get_data_filter(form_data);

      jQuery(".search-overlay").removeClass("visible");
      jQuery(".loader-filtro").addClass("loading");
      /* jQuery("html, body").animate({ scrollTop: 0 }, "slow");
        jQuery('.button-close-filter').trigger('click'); */
    });
  }

  function parseQuery(queryString) {
    let query = {};
    let pairs =
      queryString[0] === "?" ? queryString.substr(1) : queryString.split("&");
    //console.log(pairs);
    for (let i = 0; i < pairs.length; i++) {
      let pair = pairs[i].split("=");
      if (pair[0] !== "filtrocategory")
        query[decodeURIComponent(pair[0]).slice(0, -2) + "_" + i] =
          decodeURIComponent(pair[1] || "");
    }
    //console.log(query);

    return query;
  }
});
